import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import "../styles/global.css";

const Index = ({ data }) => {
  return (
    <>
      <Helmet>
        <title>Para: Not found</title>
        <meta
          name="description"
          content="With Para, nurses can work extra shifts at local healthcare facilities. Per diem, PRN jobs when you want them. Download the app today and sign up in minutes."
        />
      </Helmet>
      <Header />
      <section>
        <h1>Oops! There is no such page</h1>
        <a href="/">Go to Home page</a>
      </section>
      <Footer />
    </>
  );
};

export default Index;
